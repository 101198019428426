import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

export class MockMsalGuard implements CanActivate {
  public canActivate(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return of(true);
  }
}