import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PanelModule} from "primeng/panel";
import {ButtonDirective} from "primeng/button";
import {RadUser} from "../../shared/radUser";
import {CopyFromUser} from "../../shared/copy-from-user";
import {FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {MultiSelectModule} from "primeng/multiselect";

@Component({
  selector: 'app-copy-from-user',
  standalone: true,
  imports: [
    PanelModule,
    ButtonDirective,
    ReactiveFormsModule,
    DropdownModule,
    MultiSelectModule
  ],
  templateUrl: './copy-from-user.component.html',
  styleUrl: './copy-from-user.component.scss'
})
export class CopyFromUserComponent {
  @Input({required: true}) users: RadUser[] = [];
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  @Output() submitEvent: EventEmitter<CopyFromUser> = new EventEmitter<CopyFromUser>();

  public filteredUsers: RadUser[] = [];
  public isCopyValid: boolean = false;

  public copyFromUserForm = this.fb.nonNullable.group({
    sourceUser: new FormControl(),
    targetUsers: new FormControl(),
  });

  constructor(private fb: FormBuilder) {
  }

  onChanges($event: any): void {
    console.log($event.value);
    let userId = $event.value.Id;

    if (userId) {
      this.filteredUsers = this.users.filter(f => f.Id !== userId);
    } else {
      this.filteredUsers = [];
    }
  }

  isSubmitDisabled(): boolean {
        return !this.copyFromUserForm.controls.sourceUser.value ||
          this.copyFromUserForm.controls.sourceUser.value === '' ||
          !this.copyFromUserForm.controls.targetUsers.value ||
          this.copyFromUserForm.controls.targetUsers.value.length === 0;
  }

  cancel(): void {
    this.copyFromUserForm.reset();
    this.cancelEvent.emit();
  }

  submit(): void {
    let source: string = this.copyFromUserForm.controls.sourceUser.value.Id;
    let target: string[] = [];
    this.copyFromUserForm.controls.targetUsers.value.forEach((f: RadUser) => {
      target.push(f.Id);
    })

    const response: CopyFromUser = {
      sourceUser: source,
      targetUsers: target
    }

    this.copyFromUserForm.controls.targetUsers.setValue([]);
    this.copyFromUserForm.controls.sourceUser.setValue(undefined);
    this.isCopyValid = false;

    this.submitEvent.emit(response);
  }
}
