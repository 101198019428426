import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MsalModule } from '@azure/msal-angular';
import { automation } from './automation';
import { ResearchComponentsCoreModule, SAFETY_SETTINGS, SafetySettings } from '@corteva-research/ngx-components-core';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BreedingZoneComponent } from './breedingzone/breedingzone.component';
import { ProgramComponent } from './program/program.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect'
import { DropdownModule } from 'primeng/dropdown'
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabMenuModule } from 'primeng/tabmenu'
import { PanelModule } from 'primeng/panel'
import { InputTextModule } from 'primeng/inputtext'
import { TabViewModule } from 'primeng/tabview'
import { ToastModule } from 'primeng/toast';
import { FieldsetModule } from 'primeng/fieldset'
import { BlockUIModule } from 'primeng/blockui'
import { ColorPickerModule } from 'primeng/colorpicker'
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService, MessageService } from "primeng/api";
import { BadgeModule } from 'primeng/badge';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CalendarModule } from 'primeng/calendar'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileUploadModule } from 'primeng/fileupload';
import { AutoCompleteModule } from 'primeng/autocomplete'
import { EvaluationzoneComponent } from './evaluationzone/evaluationzone.component';
import { CreatebreedingzoneComponent } from './createbreedingzone/createbreedingzone.component';
import { CreateComponent } from './create/create.component';
import { CreateevaluationzoneComponent } from './createevaluationzone/createevaluationzone.component';
import { RegionComponent } from './region/region.component';
import { SeedstoragelocationComponent } from './seedstoragelocation/seedstoragelocation.component';
import { PeoplepickerComponent } from './peoplepicker/peoplepicker.component';
import { LEIDPickerComponent } from './leidpicker/leidpicker.component';
import { CreateseedstoragelocationComponent } from './createseedstoragelocation/createseedstoragelocation.component'
import { StationComponent } from './station/station.component';
import { CreateRegionComponent } from './createregion/createregion.component'
import { CreateprogramComponent } from './createprogram/createprogram.component';
import { CreatestationComponent } from './createstation/createstation.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoadingInterceptor } from './http/interceptors';
import { JWT_TOKEN_RETRIEVER } from './lib/services/jwt-token-retriever.service';
import { JwtTokenService } from './services/jwt-token.service';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AppService } from './app.service';
import { SharedService } from './services/shared.service';
import { CategoryManagerComponent } from './categorymanager/categorymanager.component';
import { AllocationEZProgramComponent } from './allocation-ezprogram/allocation-ezprogram.component';
import { ScheduleradminComponent } from './scheduleradmin/scheduleradmin.component';
import { RecentHistoryComponent } from './recent-history/recent-history.component';
import { ViewCommentsComponentModule } from './shared/comments/view-comments.module';
import { ManageLookupComponentModule } from './shared/manage-lookup/manage-lookup.module';
import { UserutilityComponent } from './userutility/userutility.component';
import { CreatefileComponent } from './createfile/createfile.component';
import { UploadfileComponent } from './uploadfile/uploadfile.component';
import { HistoryfileComponent } from './historyfile/historyfile.component';
import { CPFieldScientistComponent } from './cpFieldScientist/cpFieldScientist.component';
import { CreateCPFieldScientistComponent } from './createcpFieldScientist/createcpFieldScientist.component';
import { CommercialMarketComponent } from './commercialMarket/commercialmarket.component';
//import { CreateCommercialMarketComponent } from './createcommercialMarket/createcommercialmarket.component';
import { CreateCommercialMarketZoneComponent } from "./create-commercial-market-zone/create-commercial-market-zone.component";
import { MockMsalIntegrationModule, MockMsalService } from './login/msal-mock.service';
import { MockMsalGuard } from './login/login-mock';

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        CreateComponent,
        BreedingZoneComponent,
        ProgramComponent,
        EvaluationzoneComponent,
        SeedstoragelocationComponent,
        CreatebreedingzoneComponent,
        CreateevaluationzoneComponent,
        CreateprogramComponent,
        RegionComponent,
        CreateRegionComponent,
        CategoryManagerComponent,
        AllocationEZProgramComponent,
        ScheduleradminComponent,
        RecentHistoryComponent,
        CreateseedstoragelocationComponent,
        PeoplepickerComponent,
        LEIDPickerComponent,
        StationComponent,
        CreatestationComponent,
        LoginComponent,
        LogoutComponent,
        UserutilityComponent,
        CreatefileComponent,
        UploadfileComponent,
        HistoryfileComponent,
        CPFieldScientistComponent,
        CreateCPFieldScientistComponent,
        CommercialMarketComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ResearchComponentsCoreModule.forRoot('ManagerUI'),
    automation ? MockMsalIntegrationModule : ResearchComponentsMsalIntegrationModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    TabMenuModule,
    ToastModule,
    PanelModule,
    InputTextModule,
    TabViewModule,
    FieldsetModule,
    BlockUIModule,
    ColorPickerModule,
    DialogModule,
    ButtonModule,
    ListboxModule,
    CheckboxModule,
    BadgeModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    MsalModule,
    CalendarModule,
    ViewCommentsComponentModule,
    ManageLookupComponentModule,
    ProgressSpinnerModule,
    FileUploadModule,
    AutoCompleteModule,
    CreateCommercialMarketZoneComponent,
  ],
  providers: [
    automation ? [] : {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    automation ? { provide: MsalService, useClass: MockMsalService } : MsalService,
    automation ? { provide: MsalGuard, useClass: MockMsalGuard } : MsalGuard,
        MsalBroadcastService,
        AppService,
        SharedService,
        { provide: JWT_TOKEN_RETRIEVER, useClass: JwtTokenService },
        ConfirmationService,
        MessageService,
        { provide: SAFETY_SETTINGS, useValue: <SafetySettings> { targetAudience: 'Biotech', disableMessageDisplay: automation } },
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule {
}
