<p-table id="dtLookupTypes" #dt [value]="lookupTypes" dataKey="ID" editMode="row" [scrollable]="true" scrollHeight="75%" >
    <ng-template pTemplate="header">
        <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Description</th>
            <th style="text-align: center; width: 50px;">Active</th>
            <th style="text-align: center; width: 70px;">Sort</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-tier let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="tier">
            <td [pEditableColumn]="tier" [pEditableColumnField]="'Code'" [pEditableColumnRowIndex]="rowIndex">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [maxlength]="codeMaxLength" name="Code" [(ngModel)]="tier.Code" class="p-inputtext" placeholder="Code">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{tier.Code}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td [pEditableColumn]="tier" [pEditableColumnField]="'Name'" [pEditableColumnRowIndex]="rowIndex">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" name="Name" [(ngModel)]="tier.Name" class="p-inputtext" placeholder="Name">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{tier.Name}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td [pEditableColumn]="tier" [pEditableColumnField]="'Description'" [pEditableColumnRowIndex]="rowIndex">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" name="description" [(ngModel)]="tier.Description"
                            class="p-inputtext" placeholder="Description">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{tier.Description}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td [pEditableColumn]="tier" [pEditableColumnField]="'IsActive'" [pEditableColumnRowIndex]="rowIndex">
                <div class="flex align-items-center justify-content-center">
                    <input id="cbMgrIncluded2" type="checkbox" [(ngModel)]="tier.IsActive" />
                </div>
            </td>
            <td [pEditableColumn]="tier" [pEditableColumnField]="'SortOrder'" [pEditableColumnRowIndex]="rowIndex">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="number" name="SortOrder" [(ngModel)]="tier.SortOrder"
                            class="p-inputtext">
                    </ng-template>
                    <ng-template pTemplate="output">
                        <div class="flex align-items-center justify-content-center">
                            {{tier.SortOrder}}
                        </div>
                    </ng-template>
                </p-cellEditor>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="grid">
            <div class="col-7"></div>
            <div class="col-5 text-right">
                <button id="btnAddNew" *ngIf="isAddNewVisible()" type="button" pButton icon="fa fa-plus-circle" class="ui-button-primary mr-2"
                    (click)="addLookupType()" iconPos="left" label="Add New" title="Add New"></button>
                <button id="btnReset" type="button" pButton icon="fa fa-refresh" class="ui-button-primary  mr-2"
                    (click)="onResetClick()" iconPos="left" label="Reset" title="Reset"></button>
                <button id="btnSave" type="button" pButton icon="fa fa-save" class="ui-button-primary"
                    (click)="onSaveClick()" iconPos="left" label="Save" title="Save"></button>
            </div>
        </div>
    </ng-template>
</p-table>