import { BaseComponent } from './../basecomponents/base.component';
import { UserServiceModel } from './../shared/userServiceModel';
import { LeSearchResult } from './../shared/leSearchResult';
import { Constants } from './../shared/constants';
import { Component, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { UserUtilityService } from './userutility.service';
import { CommonService } from '.././shared/common.service';
import { Person } from '.././shared/person';
import { BaseService } from '../basecomponents/base.service';
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-userutility',
  templateUrl: './userutility.component.html',
  styleUrls: ['./userutility.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class UserutilityComponent extends BaseComponent {
  public lastName: string;
  public firstName: string;
  public loginName: string;
  people: Person[] = [];
  selectedItems: any[];  
  userCols:any[]; 
  saveCols:any[];
  public panelHeight = '500px';
  public hideMessage = true;
  public IsRADUser = false;
  public isROVBDSupportUser = false;
  public isUpdateEnabled = false;
  public leSearchResult: LeSearchResult[] = [];
  public userServiceResult: UserServiceModel = new UserServiceModel();

  constructor(private _confirmation: ConfirmationService, private _userUtilityService: UserUtilityService,
    private _commonService: CommonService, public override _baseService: BaseService,
    _messageService: MessageService, sharedservice: SharedService) {
      super(_baseService, _messageService, sharedservice);
  }

  buildForm() {
    if (this.isROVAdmin || this.isROVBDSupportUser || this.isROVSuperUser) {
      this.hideMessage = true;
    } 
    else {
      this.hideMessage = false;
    }

    this.SelectMenuOption('UserUtility');
  }

  override ngOnInit() { 
    this.userCols = [
      { field: 'DisplayName', header: 'Name' },
      { field: 'FirstName', header: 'First Name' },
      { field: 'LastName', header: 'Last Name' },
      { field: 'Email', header: 'Email' },
      { field: 'LoginName', header: 'Login Name' }
    ];
    this.saveCols = [
      { field: 'LeDisplayName', header: 'Name' },
      { field: 'LeId', header: 'LE Id' },
      { field: 'IsRAD', header: 'RAD' },
    ];
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.buildForm();
  }

  reset() {
    this.lastName = '';
    this.firstName = '';
    this.IsRADUser = false;
    this.isUpdateEnabled = false;
    this.leSearchResult = [];
    this.leSearchResult = this.leSearchResult.slice();
    this.people = [];
    this.people = this.people.slice();
    this.userServiceResult = new UserServiceModel();
    this.userServiceResult.AssociatedLeId = '';
    this.userServiceResult.LoginName = '';
    (document.querySelector('#txtLastName') as HTMLTextAreaElement).focus();
  }

  valueChangeFName(event:any) {
    if (event.key === 'Enter') {
      this.getLDAPUsers();
    }
  }

  valueChangeLName(event:any) {
    if (event.key === 'Enter') {
      this.getLDAPUsers();
    }
  }

  getLDAPUsers() {
    let FirstName = '';
    let LastName = '';
    let LoginName = "";

    this.firstName = this.firstName ? this.firstName.trim() : '';
    this.lastName = this.lastName ? this.lastName.trim() : '';
    this.loginName = this.loginName ? this.loginName.trim() : '';

    if (!((this.firstName || this.lastName) || this.loginName)) {
      this.DisplayWarningMessage('Warning', 'Please enter search criteria...' );
      return;
    }

    if ((this.firstName.length >= 2 || this.lastName.length >= 2) || this.loginName.length >=2) {
      FirstName = this.firstName;
      LastName = this.lastName;
      LoginName = this.loginName;
    } else {
      this.DisplayWarningMessage('Warning', 'Please enter more than 2 characters...' );
      return;
    }

    const queryString = `firstName=${FirstName}&lastName=${LastName}&loginName=${LoginName}`;
    this.people = [];
    this._commonService.getLDAPUsers(queryString)
      .subscribe(
        Result => {
          this.people = Result;
          this.leSearchResult = [];
          this.leSearchResult = this.leSearchResult.slice();
        }
      );
  }

  onRowSelect(event:any) {
    const queryString = 'userName=' + event.data.LoginName;

    this.isUpdateEnabled = false;
    this.leSearchResult = [];
    this.leSearchResult = this.leSearchResult.slice();

    this._userUtilityService.checkRADUser(queryString).subscribe({
      next: Result => {
        this.IsRADUser = Result.IsRADUser;
        this.leSearchResult = Result.Result;
        this.leSearchResult.forEach(element => {
          element.IsRAD = this.IsRADUser ? 'Y' : 'N';
        });

        if (this.IsRADUser) {
          this.DisplayInfoMessage('Info', Constants.USERALREADYEXISTS);
          if (Result.Email && event.data.Email != Result.Email) {
            this.DisplayInfoMessage('Info', 'Email has changed for this user');
            this.isUpdateEnabled = true;
          }
        } 
        else {
          if (this.leSearchResult.length === 0) {
            this.isUpdateEnabled = false;
            const dummyRow = new LeSearchResult();
            dummyRow.IsRAD = 'N';
            dummyRow.LeDisplayName = event.data.DisplayName;
            dummyRow.LeId = 'Not Found';
            this.leSearchResult.push(dummyRow);
            this.DisplayErrorMessage('Error', 'User does not exist in PRISM, please contact PRISM support.');
          } 
          else if (this.leSearchResult.length > 1) {
            this.DisplayWarningMessage('Warning', Constants.MULTIPLEUSERS);
          }
          else if (this.leSearchResult.length == 1) {
            this.isUpdateEnabled = this.leSearchResult[0].LeId !== 'Not Found';
          }
        }
        this.leSearchResult = this.leSearchResult.slice();
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  public updateRAD() {
    if (!this.leSearchResult || this.leSearchResult.length === 0) {
      this.DisplayErrorMessage('Error', 'User is not available to update in RAD.');
      return;
    } 
    else if (this.leSearchResult.length !== 1) {
        this.DisplayErrorMessage('Error', Constants.MULTIPLEUSERS);
        return;
    }
    else if (this.leSearchResult[0].LeId === 'Not Found') {
      this.DisplayErrorMessage('Error', 'User must have LEID to be updated.');
      return;
    }

    this._confirmation.confirm({
      message: Constants.CONFIRMRADUSER,
      accept: () => {
        this.userServiceResult.AssociatedLeId = this.leSearchResult[0].LeId;
        this.userServiceResult.LoginName = this.leSearchResult[0].LeUserName;

        this._userUtilityService.saveRADUser(JSON.stringify(this.userServiceResult))
          .subscribe( {
            next: Result => {
              if (Result.Status === 'SUCCESS') {
                this.DisplaySuccessMessage('Success', 'User added successfully in Research Area Database...');
                this.reset();
              } 
              else {
                this.DisplayErrorMessage('Error', Result.Message);
              }
            },
            error: error => {
              if (error === '') {
                this.DisplayErrorMessage('Error', Constants.NOSERVICE);
              } 
              else {
                this.DisplayErrorMessage('Error', error);
              }
            }
        });
      }
    });
  }
}