import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelModule } from "primeng/panel";
import { ChipModule } from "primeng/chip";
import { NgForOf, NgIf } from "@angular/common";
import { TableModule } from "primeng/table";
import { Button, ButtonDirective } from "primeng/button";
import { RadUserRoles } from 'src/app/shared/radUser';

@Component({
  selector: 'app-confirm-role-changes',
  standalone: true,
  imports: [
    PanelModule,
    ChipModule,
    NgForOf,
    TableModule,
    Button,
    ButtonDirective,
    NgIf
  ],
  templateUrl: './confirm-role-changes.component.html',
  styleUrl: './confirm-role-changes.component.scss'
})

export class ConfirmRoleChangesComponent {
  @Input({required: true}) newRoles: RadUserRoles[] = [];
  @Input({required: true}) removedRoles: RadUserRoles[] = [];
  @Output() confirmEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();

  confirm() {
    this.confirmEvent.emit();
  }

  cancel() {
    this.cancelEvent.emit();
  }
}