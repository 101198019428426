import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserCropStationProgramAssoc} from "../../shared/user-crop-station-program-assoc";
import {PanelModule} from "primeng/panel";
import {ButtonDirective} from "primeng/button";
import {NgIf} from "@angular/common";
import {TableModule} from "primeng/table";

@Component({
  selector: 'app-failed-association-submission',
  standalone: true,
  imports: [
    PanelModule,
    ButtonDirective,
    NgIf,
    TableModule
  ],
  templateUrl: './failed-association-submission.component.html',
  styleUrl: './failed-association-submission.component.scss'
})
export class FailedAssociationSubmissionComponent {
  @Input({required: true}) failedAdd: UserCropStationProgramAssoc[];
  @Input({required: true}) failedRemove: UserCropStationProgramAssoc[];
  @Output() exportEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();

  export() {
    this.exportEvent.emit();
  }

  cancel() {
    this.cancelEvent.emit();
  }
}
