import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgClass, NgIf, NgFor } from "@angular/common";
import { TableModule } from "primeng/table";
import { ChipModule } from "primeng/chip";
import { PanelModule } from "primeng/panel";
import { FileUploadModule } from "primeng/fileupload";
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadUser, RadUserImport, RadUserImportResults, RadUserRoles, ROLE_DELIMIETER } from 'src/app/shared/radUser';
import { Option } from 'src/app/shared/option';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-import-from-file',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    TableModule,
    ChipModule,
    PanelModule,
    FileUploadModule,
    NgClass,
    DialogModule,
    ReactiveFormsModule,
    MultiSelectModule
  ],
  templateUrl: './import-from-file.component.html',
  styleUrl: './import-from-file.component.scss'
})

export class ImportFromFileComponent implements OnChanges {
  @Input({required: true}) file: File | null;
  @Input({required: true}) users: RadUser[] = [];
  @Input({required: true}) existingRoles: Option[] = [];
  @Output() closeEvent = new EventEmitter();
  @Output() submitEvent: EventEmitter<RadUserImportResults> = new EventEmitter<RadUserImportResults>();

  public csvData: RadUserImport[] = [];
  public newRoles: Option[] = [];
  public isUploadDataValid: boolean = false;

  public verifyNewRolesDialogVisible: boolean = false;

  public verifyRoleForm = this.fb.nonNullable.group({
    selectedRoles: new FormControl()
  });

  constructor(private fb: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.file === null){
      this.clear();
      return;
    }

    if (this.file.name.toLowerCase().endsWith(".csv")) {
      this.readCsvFile();
    }
    else {
      this.readExcelFile();
    }
  }

  onRoleChipRemoved(login: string, role: Option) {
    this.removeRole(login, role);
  }

  validateNewRoles() {
    let input: Option[] = this.verifyRoleForm.controls.selectedRoles.value;
    this.verifyNewRolesDialogVisible = false;
    this.newRoles = [];

    if(input) {
      input.forEach(r => {
          this.newRoles.push(r);
      });
    }

    this.csvData.forEach(u => {
      if(u.HasNonExistingRoles) {
        let validRoles: Option[] = [];

        u.RolesArray.forEach(r => {
          if(this.existingRoles.findIndex(o => o.label === r.label) >= 0
            || this.newRoles.findIndex(o => o.label === r.label) >= 0) {
            validRoles.push(r);
          }

          u.RolesArray = [...validRoles];
        });
      }
    });
  }

  private getHeaderArray(csvRecordArray: any): any {
    let headers = (<string>csvRecordArray[0]).split(",");
    let headerArray = [];
    for (let i = 0; i < headers.length; i++) {
      headerArray.push(headers[i].trim());
    }

    return headerArray;
  }

  private readCsvFile(): void {
    if (this.file === null)
      return;

    let reader = new FileReader();
    reader.readAsText(this.file);
    reader.onload = () => {
      let csvData = reader.result;
      let csvRecordArray: string[] = (<string>csvData).split(/\r\n|\n/);
      let headersRow = this.getHeaderArray(csvRecordArray);

      this.processCsvData(csvRecordArray, headersRow.length);
      this.setVisibility();
    };

    reader.onerror = function (error) {
      console.log('An error occurred while reading the file: ', error);
    };
  }

  private readExcelFile(): void {
    if (this.file === null)
      return;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const wb = XLSX.read(data, {type: 'array'});
      const firstSheetName = wb.SheetNames[0];
      const ws = wb.Sheets[firstSheetName];

      let xlsData = XLSX.utils.sheet_to_csv(ws);
      let rows = (<string>xlsData).split(/\r\n|\n/);

      this.processCsvData(rows);
      this.setVisibility();
    }

    reader.onerror = function (error) {
      console.log('An error occurred while reading the file: ', error);
    };

    reader.readAsArrayBuffer(this.file);
  }

  private removeRole(login: string, role: Option) {
    const found = this.csvData.find(u => u.LoginName === login);
    if(!found) 
      return;
    
    let editingItem = {...found};
    let filtered = editingItem.RolesArray.filter(r=>r.value !== role.value);
    editingItem.RolesArray = [...filtered];
    
    const index = this.csvData.findIndex(x => x.LoginName === login);
    if (index !== -1) {
      if(editingItem.RolesArray.length > 0) {
        this.csvData[index] = editingItem;
      }
      else {
        this.csvData.splice(index, 1);
      }
    }
  }

  private setVisibility() {
    this.isUploadDataValid = this.csvData.some(d => d.Status === 0) ? false : true;
    this.verifyNewRolesDialogVisible = this.csvData.some(d => d.Status === 2) ? true : false;
  }

  private processCsvData(rows: string[], headerLength: number = 2) {
    this.csvData = [];
    this.newRoles = [];

    for (let i = 1; i < rows.length; i++) {
      let currentRecord = (rows[i]).split(",");      
      if (currentRecord.length == headerLength) {
        let login = currentRecord[0].trim().toLowerCase();
        let role = currentRecord[1].trim().toUpperCase();

        let exists = this.csvData.find(s => s.LoginName.toLowerCase() === login);
        if(exists) {
          this.ValidateRow(exists, role);
        }
        else {
          let row: RadUserImport = {
            LoginName: currentRecord[0].trim(),
            RolesArray: [],
            Status: 0,
            Notes: '',
            HasNonExistingRoles: false,
            UserValid: false
          }

          this.ValidateRow(row, role);

          this.csvData.push(row);
        }
      }
    }

    this.UpdateImportedData();
  }

  private UpdateImportedData() {
    this.csvData.forEach(d => {
      let notes: string = '';
      if(!d.UserValid) {
        notes = 'User LoginName was not found';
      }

      if(d.HasNonExistingRoles) {
        if(notes.length > 0)
          notes += '; ';
  
        notes += 'Non-Existing role(s) found';
      }

      d.Notes = notes;
      d.Status = !d.UserValid ? 0 : d.HasNonExistingRoles ? 2 : 1;
    });
  }

  private ValidateRow(row: RadUserImport, role: string) {
    let found = this.users.find(u=>u.LoginName.toLowerCase() === row.LoginName);

    row.UserValid = found ? true : false;

    if(role) {
      let optionToAdd: Option = { label: role, value: role };

      if(this.existingRoles.findIndex(o => o.label === role) < 0) {        
        row.HasNonExistingRoles = true;
  
        if(!this.newRoles.some(nr => nr.label === role)) {
          this.newRoles.push(optionToAdd);
        }
      }

      if(!row.RolesArray.some(nr => nr.label === role)) {
        row.RolesArray.push(optionToAdd);
      }
    }
  }

  cancel() {
    this.clear();
    this.closeEvent.emit();
  }

  private clear() {
    this.csvData = [];
    this.newRoles = [];
    this.isUploadDataValid = false;
    this.verifyNewRolesDialogVisible = false;
    this.verifyRoleForm.controls.selectedRoles.setValue([]);
  }

  submit() {
    let result: RadUserImportResults = { ExistingUserRoles: [], NewUserRoles: [], NewRoles: this.newRoles };

    this.csvData.forEach(d=>{
      let found = this.users.find(u=>u.LoginName.toLowerCase() === d.LoginName.toLowerCase());
      if(found) {
        let foundRoles: string[] = found.Roles.split(ROLE_DELIMIETER);
        let existingRoles: Option[] = [];
        let newRoles: Option[] = [];
        
        d.RolesArray.forEach(r => {
          if(foundRoles.some(fr=> fr.toUpperCase() === r.label))
            existingRoles.push(r);
          else
            newRoles.push(r);
        });

        if(existingRoles.length > 0) {
          let user: RadUserRoles = { Id: found.Id, LoginName: found.LoginName, RolesArray: existingRoles };          
          result.ExistingUserRoles.push(user);
        }

        if(newRoles.length > 0) {
          let user: RadUserRoles = { Id: found.Id, LoginName: found.LoginName, RolesArray: newRoles };          
          result.NewUserRoles.push(user);
        }
      }
    });

    this.clear();

    this.submitEvent.emit(result);
  }
}